/*!
	Sohne font.
*/

/* Sohne (light, regular) */
@font-face {
  font-family: Sohne;
  font-weight: 300;
  font-style: normal;
  text-rendering: optimizeSpeed;
  src: url('./soehne-extraleicht.woff2') format('woff2');
}

/* Sohne (normal, regular) */
@font-face {
  font-family: Sohne;
  font-weight: 400;
  font-style: normal;
  text-rendering: optimizeSpeed;
  src: url('./soehne-leicht.woff2') format('woff2');
}

/* Sohne (normal, italic) */
@font-face {
  font-family: Sohne;
  font-weight: 400;
  font-style: italic;
  text-rendering: optimizeSpeed;
  src: url('./soehne-leicht-kursiv.woff2') format('woff2');
}

/* Sohne (medium, regular) */
@font-face {
  font-family: Sohne;
  font-weight: 500;
  font-style: normal;
  text-rendering: optimizeSpeed;
  src: url('./soehne-buch.woff2') format('woff2');
}

/* Sohne (semibold, regular) */
@font-face {
  font-family: Sohne;
  font-weight: 600;
  font-style: normal;
  text-rendering: optimizeSpeed;
  src: url('./soehne-dreiviertelfett.woff2') format('woff2');
}

/* Sohne (bold, regular) */
@font-face {
  font-family: Sohne;
  font-weight: 700;
  font-style: normal;
  text-rendering: optimizeSpeed;
  src: url('./soehne-fett.woff2') format('woff2');
}

/* ================= Utility Classes  ================= */
.sohne {
  font-family: 'Sohne';
}
