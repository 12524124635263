@import '~lato-font/css/lato-font.css';
@import './src/v2/fonts/soehne/sohne.css';

html {
  height: 100vh;
}

* {
  font-family: 'Poppins', 'Lato', -apple-system, BlinkMacSystemFont, avenir next, avenir,
    segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

body,
#root {
  margin: 0;
  padding: 0;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* ================= Utility Classes  ================= */
.textEllipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
